/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}


::ng-deep .angular-editor {
    background-color: #2b2b2b !important;
    color: #c9d1d9 !important;
}

::ng-deep .angular-editor .angular-editor-toolbar {
    background-color: #3c3f41 !important;
}

::ng-deep .angular-editor .angular-editor-textarea {
    background-color: #2b2b2b !important;
    color: #c9d1d9 !important;
}

::ng-deep .angular-editor-button {
    color: #c9d1d9 !important;
}

::ng-deep .angular-editor-button:hover {
    background-color: #444444 !important;
}